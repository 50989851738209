import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import styles from "./Navbar.module.scss";

const Navbar = () => {
  const [showHamburger, setShowHamburger] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  useEffect(() => {
    screenSize();
    window.addEventListener("resize", screenSize);
    return () => {
      window.removeEventListener("resize", screenSize);
    };
  }, []);

  const screenSize = () => {
    if (window.innerWidth >= 767) {
      setShowHamburger(false);
    } else {
      setShowHamburger(true);
    }
  };

  const checkMenuState = () => {
    if (isHamburgerOpen) {
      setIsHamburgerOpen(false);
    }
  };

  const renderHeader = () => (
    <div className={styles.productLogo} onClick={() => checkMenuState()}>
      <Link className={styles.linkText} to="/">
        FoodSensr
      </Link>
    </div>
  );

  const renderLinks = () => (
    <>
      <Link className={styles.linkText} to="/privacy-policy">
        Privacy policy
      </Link>
      <Link className={styles.linkText} to="/faq">
        FAQs
      </Link>
    </>
  );

  return (
    <div className={styles.navbar}>
      {showHamburger ? (
        <>
          <span className={styles.hambugerHeader}>
            {renderHeader()}
            <div
              className={styles.hamburgerIcons}
              onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
            >
              {isHamburgerOpen ? <CloseOutlined /> : <MenuOutlined />}
            </div>
          </span>
          {isHamburgerOpen && (
            <div
              className={styles.hamburgerMenus}
              onClick={() => checkMenuState()}
            >
              {renderLinks()}
            </div>
          )}
        </>
      ) : (
        <>
          {renderHeader()}
          <div className={styles.navbarLinks}>{renderLinks()}</div>
        </>
      )}
    </div>
  );
};

export default Navbar;
