export const getSafeTargetValue = event => event && event.target ? event.target.value : '';

export const validateUserInfo = userInfo => {
    const { fullName, email } = userInfo;
    let hasError = true;
    let errorMessage = '';
    if(!fullName.length){
        errorMessage = 'Please enter your name';
    } else if(!email.length){
        errorMessage = 'Please enter your email';
    } else {
        // eslint-disable-next-line no-useless-escape
        const validRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        hasError = !validRegex.test(email.toLowerCase());
        errorMessage = 'Please enter valid email';
    }
    return { hasError, errorMessage };
}