import React from "react";
import { Collapse } from "antd";
import Navbar from '../Navbar';
import styles from "./FAQ.module.scss";

const { Panel } = Collapse;

const FAQ = () => {
  return (
    <>
    <Navbar />
    <div className={styles.contentWrapper}>
      <p className={styles.pageHeader}>FAQs</p>
      <div className={styles.accordionSection}>
        <Collapse accordion size="large">
          <Panel header="What is FoodSensr?" key="1">
            <p>
              FoodSensr is an app that helps you identify foods that may trigger
              your allergies by measuring your stress levels after consuming
              them.
            </p>
          </Panel>
          <Panel header="How does FoodSensr work?" key="2">
            <p>
              FoodSensr uses data from your wearable device, such as a Garmin
              watch, to detect changes in your heart rate variability and
              determine your stress levels. By tracking your stress levels after
              consuming certain foods, you can quickly and accurately identify
              potential allergens.
            </p>
          </Panel>
          <Panel
            header="What kind of wearables are compatible with FoodSensr?"
            key="3"
          >
            <p>
              FoodSensr is compatible with a wide range of wearable devices,
              including Garmin watches and other devices that track heart rate
              variability.
            </p>
          </Panel>
          <Panel header="Is FoodSensr free to use?" key="4">
            <p>
              FoodSensr offers both free and premium versions of the app. The
              free version provides basic features such as logging your meals
              and tracking your symptoms. The premium version offers additional
              features such as personalized recommendations and advanced
              analytics.
            </p>
          </Panel>
          <Panel header="Is my data secure with FoodSensr?" key="5">
            <p>
              Yes, FoodSensr takes the privacy and security of your data very
              seriously. We use industry-standard security measures to protect
              your data and we will never share your data with third parties
              without your consent.
            </p>
          </Panel>
          <Panel header="Can I export my data from FoodSensr?" key="6">
            <p>
              Yes, FoodSensr allows you to export your data in a variety of
              formats, so you can easily share it with your healthcare provider
              or analyze it on your own.
            </p>
          </Panel>
        </Collapse>
      </div>
    </div>
    </>
  );
};

export default FAQ;
