import React from "react";
import Navbar from '../Navbar';
import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => {
  return (
    <>
    <Navbar />
    <div className={styles.privacyPolicySection}>
      <p className={styles.pageHeader} id="privacyPolicy">Privacy Policy</p>
      <div className={styles.contentWrapper}>
        <p>
          FoodSensr is committed to protecting your privacy. This Privacy Policy
          explains how we collect, use, and share information about you when you
          use our app.
        </p>
        <h3 className={styles.sectionHeader}>Information we collect</h3>
        <p>
          We may collect information about you when you use our app, including:
        </p>
        <ul>
          <li>
            Personal information such as your name, email address, and other
            contact details.
          </li>
          <li>
            Health information such as your stress levels and food allergy
            symptoms, which are collected from your wearable device.
          </li>
          <li>
            Information about your use of the app, including log data such as
            your IP address, device type, and app usage data.
          </li>
        </ul>
        <h3 className={styles.sectionHeader}>How we use your information</h3>
        <p>
          We use your information to provide you with the services and features
          of our app, including:
        </p>
        <ul>
          <li>
            Identifying problem foods that trigger your allergies by measuring
            your stress levels after consuming them.
          </li>
          <li>
            Logging your meals, tracking your symptoms, and providing you with
            personalized recommendations on what to eat and what to avoid.
          </li>
          <li>
            Improving the quality and functionality of our app and developing
            new features.
          </li>
        </ul>
        <p>
          We may also use your information to communicate with you about updates
          to our app, promotions, and other marketing-related purposes.
        </p>
        <h3 className={styles.sectionHeader}>How we share your information</h3>
        <p>
          We may share your information with third-party service providers who
          assist us in providing our app, including our hosting provider, email
          provider, and analytics providers.
        </p>
        <p>
          We may also disclose your information if required by law or if we
          believe that such disclosure is necessary to protect our rights or the
          safety of others.
        </p>
        <h3 className={styles.sectionHeader}>Your choices</h3>
        <p>
          You can choose not to provide us with certain information, but this
          may limit your ability to use certain features of our app.
        </p>
        <p>
          You can also opt out of receiving marketing communications from us by
          clicking the "unsubscribe" link in our emails.
        </p>
        <h3 className={styles.sectionHeader}>Data retention</h3>
        <p>
          We will retain your information for as long as necessary to provide
          our app to you and for as long as required by law.
        </p>
        <h3 className={styles.sectionHeader}>Security</h3>
        <p>
          We take reasonable measures to protect your information from
          unauthorized access, alteration, or destruction.
        </p>
        <h3 className={styles.sectionHeader}>Changes to this Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page.
        </p>
        <h3 className={styles.sectionHeader}>Contact us</h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at <a className={styles.supportLink} href='mailto:sibi.kabil@gmail.com'>FoodSensr Support</a>.
        </p>
      </div>
    </div>
    </>
  );
};

export default PrivacyPolicy;
