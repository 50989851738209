import React, { useEffect, useState } from "react";
import { Input, Button, notification, Modal } from "antd";
import {
  UserOutlined,
  MailOutlined,
  CheckCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import Navbar from "../Navbar";
import { db } from "../../firebase";
import styles from "./WaitlistPage.module.scss";
import { getSafeTargetValue, validateUserInfo } from "./WaitlistPage.helper";

const Waitlistpage = () => {
  const [userInfo, setUserInfo] = useState({ fullName: "", email: "" });
  const [showModal, setShowModal] = useState(false);

  const showIntro = JSON.parse(sessionStorage.getItem("introCompleted"));

  useEffect(() => {
    if (!showIntro) {
      const revealContent = [
        { top: 0 },
        { top: "-100vh", display: "none", height: "0vh" },
      ];
      const revealingDuration = {
        duration: 400,
        iterations: 1,
        fill: "forwards",
      };
      let introPage = document.getElementsByTagName("div")[1];
      let timer = setTimeout(() => {
        introPage.animate(revealContent, revealingDuration);
        sessionStorage.setItem("introCompleted", "true");
      }, 600);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showIntro]);

  const [api, contextHolder] = notification.useNotification();

  const showNotification = (message) => {
    api.warning({
      message,
      placement: "topRight",
    });
  };

  const addToWaitlist = (userInfo) => {
    const { fullName, email } = userInfo;
    const { hasError, errorMessage } = validateUserInfo(userInfo);
    if (hasError) {
      showNotification(errorMessage);
    } else {
      const updatedEmail = email.toLowerCase();
      db.collection("users")
        .doc(updatedEmail)
        .set({ fullName, email: updatedEmail });
      setShowModal(true);
      setUserInfo({ fullName: "", email: "" });
    }
  };

  return (
    <>
      {contextHolder}
      {!showIntro && (
        <div className={styles.introPage}>
          <span className={styles.productName}>FoodSensr</span>
        </div>
      )}
      <Navbar />
      <div className={styles.waitlistPage_background}>
        <h1 className={styles.productSlogan}>
          Elevate your diet with <span className={styles.productTitle}>FoodSensr</span>
        </h1>
        <div className={styles.contentWrapper}>
          <div className={styles.leftSection}>
            <p>
              FoodSensr integrates with Garmin devices, making it easy for users
              to track their stress levels and monitor their food intake all in
              one place
            </p>

            <p>
              With FoodSensr, you can easily log your meals, track your
              symptoms, and get personalized recommendations on what to eat and
              what to avoid. Our comprehensive database of common food allergens
              ensures that you always have the information you need to make
              informed choices about your diet.
            </p>

            <p>
              Whether you're dealing with a known food allergy or trying to
              identify potential triggers, FoodSensr is the perfect tool to help
              you manage your allergies and stay healthy. Try FoodSensr today
              and take the first step towards a healthier, happier you!
            </p>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.rightContent}>
              <p className={styles.rightSectionHeader}>Join our waitlist now</p>
              <Input
                size="large"
                placeholder="Full name..."
                prefix={<UserOutlined />}
                value={userInfo.fullName}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, fullName: getSafeTargetValue(e) });
                }}
              />
              <br />
              <Input
                size="large"
                placeholder="Email address..."
                prefix={<MailOutlined />}
                value={userInfo.email}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, email: getSafeTargetValue(e) });
                }}
              />
              <br />
              <Button
                className={styles.joinButton}
                type="primary"
                onClick={() => addToWaitlist(userInfo)}
              >
                Join
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        centered
        className={styles.waitlistModal}
      >
        <div className={styles.modalContent}>
          <CheckCircleOutlined className={styles.waitlistDoneIcon} />
          <div className={styles.successMessage}>
            You are on the waitlist!
          </div>
          <div className={styles.infoMessage}>
            We'll let you know when FoodSensr is ready.
          </div>
          <div className={styles.infoMessage}>
            Meanwhile, let your friends know about FoodSensr{" "}
            <span className={styles.copyIcon} title="Copy URL" onClick={() => {
              navigator.clipboard.writeText('https://www.foodsensr.com')
              api.success({
                message: 'URL copied',
                placement: "topRight",
                duration: 2,
              });
            }
            }>
              <CopyOutlined />
              <span />
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Waitlistpage;
