import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WaitlistPage from "./Components/WaitlistPage";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import FAQ from "./Components/FAQ";

const App = () => {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<WaitlistPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
    </Router>
  );
};

export default App;
